<template>
  <div v-if='videoUrl' class='h-full'>
    <vimeo-player
      ref='liveVideoPreviewPlayer'
      :options='options'
      :video-url='videoUrl' />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import isEmpty  from 'lodash/isEmpty'

export default {
  name: 'LiveVideoPreviewContentVimeo',
  props: [
  'showingContent'
  ],
  data () {
    return {
      videoUrl: '',
      options: {
        responsive: true,
        background: true,
        quality: 240
      }
    }
  },
  watch: {
    selectedContentSourceId: {
      handler: function (newVal) {
        if (newVal) {
          this.getLiveContentVideoUrl(newVal).then((resp) => {
            this.videoUrl = resp
          })
        }
      },
      immediate: true
    }
  },
  computed: {
    hasShowingContent () {
      return !isEmpty(this.showingContent)
    },
    selectedContentSourceId () {
      if (this.hasShowingContent) {
        let sourceUrlArray = this.showingContent.data.sourceUrl.split('/')
        return sourceUrlArray[sourceUrlArray.length - 1]
      } else {
        return 0
      }
    }
  },
  methods: {
    ...mapActions('contents', [
      'getLiveContentVideoUrl'
    ]),
  }
}
</script>
